<template>
  <div class="referral-copy-link">
    <app-text type="h5" class="lg:tw-hidden tw-p-0 tw-mt-0 tw-mb-4 tw-text-white">
      <app-text tag="span" weight="semi-bold" class="tw-text-white">
        Earn
      </app-text>
      <app-text tag="span" weight="semi-bold" class="tw-relative tw-text-white">
        1%
        <app-text class="tw-bg-jb-teal tw-h-1/6 tw--left-0 tw-absolute tw-bottom-0 tw-w-5 tw-rounded" />
      </app-text>
      and refer a friend by sharing the link below*
    </app-text>
    <app-text type="h4" weight="semi-bold" class="lg:tw-block tw-text-jb-ink tw-hidden tw-p-0 tw-mt-0 tw-mb-2 tw-leading-tight">
      Refer a friend and earn 1%*
    </app-text>
    <app-text type="p" size="sm" class="lg:tw-block tw-text-jb-grey-700 tw-hidden tw-w-4/5 tw-mb-4">
      Copy your referral link to share with friends.
    </app-text>
    <app-copy-text class="!tw-py-space-2 tw-relative tw-z-1 lg:tw-w-3/4" :link="link" />
    <div class="gift-bg tw-bg-jb-indigo-darker sm:tw-flex tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-justify-end tw-hidden tw-rounded-r">
      <gifty-illustration class="tw-bottom-3 lg:tw-right-2 sm:tw-right-4 tw-absolute tw-z-0" />
    </div>
  </div>
</template>

<script>
import AppLink from '@/components/AppLink';
import AppText from '@/components/AppText';
import AppCopyText from '@/components/AppCopyText';
import GiftyIllustration from '@/assets/svg/gifty-illustration.svg';

export default {
  name: 'ReferralCopyLink',
  components: { GiftyIllustration, AppCopyText, AppLink, AppText },
  props: {
    link: {
      type: String,
      default: undefined
    }
  }
};
</script>

<style lang="scss">
.referral-copy-link {
  background-color: theme('colors.jb-indigo.DEFAULT');

  @include sm-up {
    --gradient-start: theme('colors.jb-indigo.darker');
    --gradient-end: theme('colors.jb-indigo.DEFAULT');

    background: linear-gradient(-45deg, var(--gradient-start) 24%, var(--gradient-end) 24%);
  }

  @include lg-up {
    --gradient-start: theme('colors.jb-indigo.darker');
    --gradient-end: theme('colors.white');

    background: linear-gradient(-45deg, var(--gradient-start) 33%, var(--gradient-end) 30%);
  }
}
</style>
