<template>
  <div class="referral-line-chart tw-w-full">
    <line-chart
      ref="linechart"
      :animate="notMobile"
      :show-circle="false"
      :circle-attrs="circleAttrs"
      :range-start="rangeStart"
      :range-end="rangeEnd"
      :tooltip-content="updatedTooltipContent"
      :data="data"
      :show-axis="xsUp && notMobile"
      :width="smUp ? 750 : xsUp ? 450 : 300"
      :height="250"
      :stroke-width="2"
      :zero-scale="true"
      :show-area="true"
      :show-line="true"
      :show-outline="false"
      stroke-color="#9D67E4"
      fill-color="#CCB0F1"
      :x-axis-padding="0.01"
      x-axis-group="month"
      @mouse-event-changed="updateContent" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import LineChart from '@/components/charts/LineChart.vue';
import Breakpoints from '@/mixins/Breakpoints';
import money from '@/services/money';

export default {
  name: 'ReferralLineChart',
  components: { LineChart },
  mixins: [Breakpoints([451, 768])],
  props: {
    data: {
      type: Object,
      required: true
    },
    rangeStart: {
      type: String,
      required: false,
      default: ''
    },
    rangeEnd: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      mouseData: {},
      circleAttrs: { strokeWidth: 2, fill: '#9D67E4', stroke: '#fff' }
    };
  },
  computed: {
    xsUp() {
      return this.windowBreakpoint >= 451;
    },
    smUp() {
      return this.windowBreakpoint >= 768;
    },
    notMobile() {
      return this.$deviceType !== 'mobile';
    },
    updatedTooltipContent() {
      if (this.mouseData.label === null) return null;
      const prev = this.mouseData.prev || { value: 0 };
      const isPending = this.rangeStart && dayjs(this.mouseData.label).isAfter(dayjs(this.rangeStart));

      const total = this.mouseData.value;
      const pending = isPending ? this.mouseData.value - prev.value : 0;
      const paid = !isPending ? this.mouseData.value - prev.value : 0;

      return `
        <div class="tippy-referral">
          <div><strong>${dayjs(this.mouseData.label).format('MMMM D, YYYY')}</strong></div>
          <div class="tippy-referral__content">
              <div>Pending <span>${money(parseFloat(pending > 0 ? pending : 0), false, '$', '')}</span></div>
              <div>Paid <span>${money(parseFloat(paid > 0 ? paid : 0), false, '$', '')}</span></div>
              <hr>
              <div>Total <span>${money(parseFloat(total > 0 ? total : 0), false, '$', '')}</span></div>
          </div>
        </div>
      `;
    }
  },
  watch: {
    windowBreakpoint() {
      setTimeout(this.$refs.linechart.onChartUpdated, 200);
    }
  },
  methods: {
    updateContent(mouseData) {
      this.mouseData = mouseData;
      this.$refs.linechart.updatedTooltipContent = this.updatedTooltipContent;
    }
  }
};
</script>

<style lang="scss">
.referral-line-chart {
  .axis-x {
    @apply tw-hidden;
  }

  .axis-y text {
    @apply tw-transform tw--translate-x-space-2;
  }
}

.tippy-referral {
  &__content {
    @apply tw-flex tw-flex-col tw-mt-4 tw-w-40;

    > div {
      @apply tw-text-left tw-font-bold;

      > span {
        @apply tw-float-right tw-text-right tw-font-normal;
      }
    }

    > hr {
      @apply tw-w-full;
    }
  }
}
</style>
