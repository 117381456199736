<template>
  <div class="referral-management lg:tw-max-w-screen-app-content-width tw-p-layout-1 sm:tw-p-layout-3 sm:tw-mb-layout-5 tw-mb-layout-4 tw-mx-auto">
    <section>
      <app-heading tag="h2" size="h3" weight="semi-bold" color="headings" class="tw-mb-space-4 md:tw-text-h2">
        Referral Management
      </app-heading>
      <div class="lg:tw-flex-nowrap tw-flex tw-flex-wrap-reverse tw-justify-between">
        <div class="lg:tw-w-3/5 tw-mt-layout-2 lg:tw-mt-0 tw-relative tw-w-full tw-h-auto">
          <app-stat-summary
            class="tw-border-grey-lighter lg:tw-mr-4 sm:tw-h-full tw-h-auto tw-border tw-border-solid tw-rounded"
            :stats="isLoadingSummary ? Array(4).fill({}) : stats">
            <template v-if="isLoadingSummary" #stat>
              <skeleton-box class="tw-mb-space-2 sm:tw-w-4/5 sm:tw-ml-auto tw-h-space-5 tw-w-32 tw-ml-0 tw-rounded-full" />
              <skeleton-box class="tw-w-layout-5 sm:tw-w-1/2 sm:tw-ml-auto tw-h-space-3 tw-mt-0 tw-ml-0 tw-rounded-full" />
            </template>
          </app-stat-summary>
          <div v-if="!showReportSection" class="tw-mt-space-2 tw-ml-space-2 sm:tw-absolute xs:tw-mb-0">
            <app-text size="xs" weight="medium">
              * Commission is calculated and payment shall be subject to
              <app-link :external="true" :href="referralTermsLink" class="tw-text-jb-indigo hover:tw-underline tw-cursor-pointer">
                Referral Program Terms and Conditions
              </app-link>
            </app-text>
          </div>
        </div>
        <referral-copy-link
          class="tw-bg-jb-indigo tw-p-layout-1 tw-border-grey-lighter lg:tw-max-w-lg md:tw-mb-0 lg:tw-w-2/5 tw-relative tw-w-full tw-h-full tw-border tw-border-solid tw-rounded"
          :link="referralLink" />
      </div>
    </section>
    <section class="tw-mt-layout-4 lg:tw-mt-layout-5 tw-border-t-1 tw-border-jb-indigo-darker tw-border-solid">
      <div class="tw-bg-jb-indigo-lighter tw-p-space-4 tw-pb-layout-3">
        <div class="xs:tw-flex-row xs:tw-items-center tw-flex tw-flex-col tw-justify-between">
          <div class="xs:tw-mb-0 tw-mb-space-4 tw-flex">
            <div class="tw-mr-space-2">
              <i class="el-icon-info tw-text-jb-indigo tw-leading-5" />
            </div>
            <div class="xs:tw-ml-space-4 twl-ml-0">
              <app-text weight="semi-bold" class="tw-text-jb-ink">
                Payment Setup
              </app-text>
              <br>
              <app-text weight="medium" class="tw-text-jb-grey-700">
                Manage the payment setup or history for your referrals.
              </app-text>
            </div>
          </div>
          <div class=" sm:tw-flex-row tw-ml-space-4 tw-flex tw-flex-col tw-ml-0">
            <app-button
              state="secondary"
              size="mini"
              class="tw-text-jb-indigo tw-border-jb-indigo xs:tw-w-auto sm:tw-mb-0 sm:tw-mr-space-4 tw-p-space-4 tw-mb-space-2 tw-w-full tw-bg-transparent tw-border"
              @click="$router.push({ name: 'referral-setup' });">
              Edit Payment Details
            </app-button>
            <app-button
              state="secondary"
              size="mini"
              class="tw-text-jb-indigo tw-border-jb-indigo xs:tw-w-auto tw-p-space-4 tw-w-full tw-bg-transparent tw-border"
              @click="$router.push({ name: 'referral-history' });">
              Payment History
            </app-button>
          </div>
        </div>
      </div>
    </section>
    <section v-if="showReportSection" class="tw-mt-layout-3 lg:tw-mt-layout-4 tw-mb-layout-2 lg:tw-mb-layout-3">
      <app-heading tag="h2" size="h3" weight="semi-bold" color="headings" class="tw-mb-space-4 md:tw-text-h2">
        Referral Performance Report
      </app-heading>
      <div
        v-if="showLineChart"
        v-loading="isLoadingSummary"
        class="referral-line-chart-wrapper tw-border-grey-lighter tw-p-layout-1 sm:tw-p-layout-2 tw-mb-space-2 tw-flex tw-flex-col tw-w-full tw-bg-white tw-border tw-border-solid tw-rounded">
        <referral-line-chart
          v-if="showLineChart && hasTimelineData"
          :data="timeline"
          :range-start="rangeStart"
          :range-end="rangeEnd" />
        <div class="tw-pt-space-2 tw-flex tw-justify-end">
          <div class="tw-flex tw-items-center">
            <div class="tw-flex tw-items-center">
              <span class="tw-bg-jb-grey-50 tw-mr-space-2 tw-w-5 tw-h-5 tw-rounded" />
              <app-text weight="medium" size="sm" class="tw-leading-none">
                Pending
              </app-text>
            </div>
          </div>
        </div>
      </div>
      <app-text v-if="showLineChart && hasTimelineData" type="p" size="xs" weight="medium" class="tw-ml-space-2">
        * Projected Commission payout between date of last payout and current date is an estimation.
        Final payout calculations shall be based on and subject to
        <app-link :external="true" :href="referralTermsLink" class="tw-text-jb-indigo hover:tw-underline tw-cursor-pointer">
          Referral Program Terms and Conditions
        </app-link>
      </app-text>
      <referral-table v-if="hasDetailsData" :data="details" :class="{ ['tw-mt-layout-1']: showLineChart }" />
    </section>
    <section id="faq" class="tw-mt-layout-3 lg:tw-mt-layout-4" :class="{ ['sm:!tw-mt-layout-5']: !showReportSection }">
      <app-heading tag="h2" size="h3" weight="semi-bold" color="headings" class="md:tw-mb-layout-2 tw-mb-space-4 md:tw-text-h2 lg:tw-text-h1 md:tw-font-medium sm:tw-text-center">
        Frequently Asked Questions
      </app-heading>
      <app-accordion v-model="faqActiveName" :data="faqData">
        <template slot="title" slot-scope="row">
          <div class="tw-flex tw-justify-between tw-w-full">
            {{ row.item.title }}
            <i :class="faqActiveName === row.item.name ? 'el-icon-minus' : 'el-icon-plus'" />
          </div>
        </template>
      </app-accordion>
    </section>
  </div>
</template>

<script>
import AppText from '@/components/AppText.vue';
import AppLink from '@/components/AppLink.vue';
import AppHeading from '@/components/AppHeading.vue';
import AppButton from '@/components/AppButton.vue';
import AppStatSummary from '@/components/AppStatSummary.vue';
import ReferralLineChart from '@/components/referral/referralLineChart.vue';
import ReferralCopyLink from '@/components/referral/ReferralCopyLink.vue';
import ReferralTable from '@/components/referral/ReferralTable.vue';
import SkeletonBox from '@/components/skeletons/SkeletonBox.vue';
import AppAccordion from '@/components/AppAccordion';
import { FAQ_DATA } from '@/constants/referral';

export default {
  name: 'ReferralManagment',
  components: { AppText, AppLink, AppHeading, AppButton, AppStatSummary, ReferralLineChart, ReferralCopyLink, ReferralTable, SkeletonBox, AppAccordion },
  data() {
    return {
      isLoadingSummary: true,
      isLoadingDetails: true,
      referralTermsLink: 'https://jumbleberry.com/referral-program.html',
      faqActiveName: ''
    };
  },
  computed: {
    stats() {
      return this.$store.getters.referralStats;
    },
    timeline() {
      return this.$store.getters.referralTimeline;
    },
    details() {
      return this.$store.getters.referralDetails;
    },
    referralLink() {
      return this.$store.getters.referralLink;
    },
    showLineChart() {
      return this.isLoadingSummary || this.hasTimelineData;
    },
    showReportSection() {
      return this.isLoadingSummary || this.hasTimelineData || this.hasDetailsData;
    },
    hasTimelineData() {
      return this.timeline && this.timeline.values && this.timeline.values.length;
    },
    hasDetailsData() {
      return this.details && this.details.length;
    },
    rangeStart() {
      if (this.timeline.pendingCredits) return this.timeline.labels[this.timeline.labels.length - 2];
      return undefined;
    },
    rangeEnd() {
      return this.timeline.labels[this.timeline.labels.length - 1];
    },
    faqData() {
      return FAQ_DATA;
    }
  },
  async created() {
    await this.getSummary();
    await this.getDetails();
  },
  methods: {
    async getSummary() {
      try {
        this.isLoadingSummary = true;
        await this.$api.referral.fetchSummary({ storeAction: 'fetchReferralSummary' });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.isLoadingSummary = false;
      }
    },
    async getDetails() {
      try {
        this.isLoadingDetails = true;
        await this.$api.referral.fetchDetails({ storeAction: 'fetchReferralDetails' });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.isLoadingDetails = false;
      }
    }
  }
};
</script>

<style>
  #faq .el-collapse-item {
    margin-bottom: theme('spacing.space-2');
  }
  </style>
