import { render, staticRenderFns } from "./AppStatSummary.vue?vue&type=template&id=2c30d8c7&functional=true&"
import script from "./AppStatSummary.vue?vue&type=script&lang=js&"
export * from "./AppStatSummary.vue?vue&type=script&lang=js&"
import style0 from "./AppStatSummary.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports