<template functional>
  <div
    :ref="data.ref"
    class="app-stat-summary"
    :class="[ ...(data.staticClass || []), ...(data.class || []) ]"
    :style="[ ...(data.staticStyle || []), ...(data.style || []) ]"
    v-bind="{ ...data.attrs, [$options._scopeId]: '' }"
    v-on="listeners">
    <slot name="header" />

    <div
      class="app-stat-summary-wrapper sm:tw-grid tw-items-center tw-block tw-h-full tw-overflow-hidden tw-rounded"
      :style="`grid-template-columns: repeat(${props.stats.length}, 1fr);`">
      <div v-for="(stat, index) in props.stats" :key="'stat-'+index" class="app-stat-summary-item">
        <slot name="stat" :stat="stat" :value="stat[props.valueKey]" :label="stat[props.labelKey]" :index="index">
          <div class="app-stat-summary-value tw-text-headings tw-text-2xl tw-font-medium">
            {{ stat[props.valueKey] }}
          </div>
          <span class="app-stat-summary-label tw-text-xs tw-font-medium tw-text-gray-700">
            {{ stat[props.labelKey] }}
          </span>
        </slot>
      </div>
    </div>

    <slot name="footer" />
  </div>
</template>

<script>
export default {
  name: 'AppStatSummary',
  props: {
    stats: {
      type: Array,
      required: true
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    valueKey: {
      type: String,
      default: 'value'
    }
  }
};
</script>

<style lang="scss">
.app-stat-summary-item {
  @apply
    tw-w-full tw-p-layout-2 tw-text-left tw-bg-white
    tw-border-b tw-border-solid tw-border-grey-lighter
    sm:tw-flex sm:tw-flex-col sm:tw-items-center sm:tw-px-layout-1 sm:tw-py-layout-3 sm:tw-text-center
    sm:tw-border-b-0 sm:tw-border-r sm:tw-justify-center sm:tw-h-full
  ;

  &:last-child {
    @apply tw-border-0;
  }
}
</style>
