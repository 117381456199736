<template>
  <div class="referral-table">
    <el-table :data="data" class="tw-font-medium tw-rounded" :row-class-name="tableRowClassName">
      <el-table-column prop="signup_date" label="Signup Date" width="120" />
      <el-table-column prop="org_name" label="User" min-width="150" />

      <el-table-column prop="referral_status" label="Status" width="120">
        <template slot-scope="scope">
          <app-text is="p" class="tw-text-sm tw-capitalize">
            <component :is="statusIcon(scope.row.referral_status)" /> {{ scope.row.referral_status }}
          </app-text>
        </template>
      </el-table-column>

      <el-table-column prop="amount_earned" label="Earned ($)" width="120" align="right" />
      <el-table-column prop="commission_expiration_date" label="Expiration Date" width="150" />
    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import ActiveIcon from '@/assets/svg/active-icon.svg';
import PendingIcon from '@/assets/svg/pending-icon.svg';
import ExpiredIcon from '@/assets/svg/expired-icon.svg';

export default {
  name: 'ReferralTable',
  components: {
    ActiveIcon,
    PendingIcon,
    ExpiredIcon,
    'el-table': Table,
    'el-table-column': TableColumn
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    statusIcon(status) {
      const statusIcons = {
        pending: 'pending-icon',
        active: 'active-icon',
        expired: 'expired-icon'
      };
      return statusIcons[status];
    },
    tableRowClassName({ row, rowIndex }) {
      let className = '';
      className += row.status === 'expired' ? ' row-expired' : '';
      return className;
    }
  }
};
</script>

<style lang="scss">
.referral-table {
  .el-table,
  table,
  tr,
  th {
    @apply tw-border-none tw-bg-transparent;
  }

  .el-table::before {
    @apply tw-bg-transparent;
  }

  tbody {
    @apply tw-bg-white tw-rounded-full tw-border-grey-lighter;
  }

  th {
    @apply tw-font-medium tw-text-jb-grey-700;
  }

  td {
    @apply tw-text-jb-ink tw-bg-transparent;
  }

  th,
  td {
    .cell {
      @apply tw-whitespace-nowrap tw-px-space-5;

      svg {
        @apply tw-align-middle;
      }
    }

    &:first-child {
      .cell {
        @apply tw-pl-0;
      }
    }

    &:last-child {
      .cell {
        @apply tw-pr-0;
      }
    }
  }

  .row-expired .cell {
    @apply tw-opacity-50;
  }

  .el-table__header-wrapper {
    table {
      th {
        @apply tw-border-0;
      }

      th:first-child {
        @apply tw-pl-space-4;
      }

      th:last-child {
        @apply tw-pr-space-4;
      }
    }
  }

  .el-table__body-wrapper {
    @apply tw-border tw-rounded tw-border-solid tw-border-grey-lighter;

    table {
      td {
        @apply tw-border-b tw-border-grey-lighter;
      }

      tr {
        td:first-child {
          @apply tw-pl-space-4;
        }

        td:last-child {
          @apply tw-pr-space-4;
        }

        &:last-child {
          td {
            @apply tw-border-b-0;
          }
        }
      }
    }
  }

  .el-table__body tr:hover > td {
    @apply tw-bg-white;
  }
}
</style>
